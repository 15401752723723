p {
  white-space: pre-line;
  border: 0.1em dashed black;
  padding: 1em;
  background-color: #E7EBF0;
}

ul {
  list-style-type: none;
  padding: em;
  margin: 0em;
}

li {
  margin: 1em 0 1em 0;
}

.loading {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

a {
  color: black;
}

a:hover {
  color: darkgray;
}

h2 a {
  text-decoration: none;
}

/* Line break on mobile */
@media (min-width: 800px){
.mobile-line-break {
   display: none;
      }
  }